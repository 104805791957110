<template>
	<v-card height="100%" flat tile class="wr_grey_1">
		<v-row no-gutters class="full-height-wr">
			<v-col sm="4">
				<v-card tile outlined height="100%">
					<v-card-text
						align="center"
						class="subtitle-1 black--text font-weight-bold"
					>
						{{ $t("app.messages") }}
					</v-card-text>

					<v-divider></v-divider>

					<v-toolbar flat color="transparent">
						<v-tabs
							v-model="current_tab"
							active-class="black--text font-weight-bold"
							centered
							:slider-color="tabColor"
							fixed-tabs
						>
							<v-tab
								v-for="(tab, index) in tabs"
								:key="index"
								:href="`#${tab.value}`"
								class="title"
							>
								<v-icon
									:left="!$vuetify.rtl"
									:right="$vuetify.rtl"
									:color="current_tab === tab.value ? tabColor : null"
									>{{ $vuetify.icons.values[tab.icon] }}</v-icon
								>
								{{ $t(tab.name) }}
							</v-tab>
						</v-tabs>
					</v-toolbar>

					<v-tabs-items v-model="current_tab">
						<v-tab-item
							v-for="(tab, index) in tabs"
							:key="'c' + index"
							:value="tab.value"
						>
							<Notifications :type="tab.value"></Notifications>
						</v-tab-item>
					</v-tabs-items>
				</v-card>
			</v-col>

			<v-col>
				<PreviewNotification
					v-if="preview_mode"
					:type="current_tab"
				></PreviewNotification>

				<!-- If preview mode is not set -->
				<SendNotification v-else></SendNotification>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import Notifications from "@/components/shared/notification/Index.vue";
import SendNotification from "@/components/shared/notification/Send.vue";
import PreviewNotification from "@/components/shared/notification/Preview.vue";

export default {
	name: "NotificationsLayout",

	data() {
		return {
			current_tab: "inbox",
		};
	},

	components: {
		Notifications,
		SendNotification,
		PreviewNotification,
	},

	created() {
		this.init();
	},

	watch: {
		current_tab(newVal, oldVal) {
			this.$store.commit("notification/UPDATE_NOTIFICATION_MODE", {
				preview_mode: false,
				selected_id: null,
			});
		},
	},

	computed: {
		...mapState({
			preview_mode: (state) => state.notification.preview_mode,
		}),

		tabColor() {
			if (this.current_tab == this.$defines.NOTIFICATION_TYPE_INBOX) {
				return "success";
			}
			if (this.current_tab == this.$defines.NOTIFICATION_TYPE_OUTBOX) {
				return "amberDarken1";
			}
		},

		tabs() {
			return [
				{
					name: "app.inbox",
					icon: "arrow_down",
					value: this.$defines.NOTIFICATION_TYPE_INBOX,
				},
				{
					name: "app.outbox",
					icon: "straight_arrow_up",
					value: this.$defines.NOTIFICATION_TYPE_OUTBOX,
				},
			];
		},
	},

	methods: {
		async init() {
			try {
				this.$loader.start();
				// Get inbox messages
				await this.getInboxMessages();
				// Get outbox messges
				await this.getOutboxMessages();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async getInboxMessages() {
			await this.$store.dispatch("notification/list", {
				type: this.$defines.NOTIFICATION_TYPE_INBOX,
			});
		},

		async getOutboxMessages() {
			await this.$store.dispatch("notification/list", {
				type: this.$defines.NOTIFICATION_TYPE_OUTBOX,
			});
		},
	},
};
</script>
