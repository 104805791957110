<template>
	<v-card flat height="100%" tile outlined>
		<v-card-text align="center" class="subtitle-1 black--text font-weight-bold">
			{{ $t("app.send_new_msg") }}
			<v-icon right color="black">$vuetify.icons.values.plus</v-icon>
		</v-card-text>

		<v-divider></v-divider>

		<v-card-text class="pt-10">
			<v-form ref="form">
				<v-select
					v-model="recipient_type"
					:label="$t('app.to')"
					outlined
					:items="recipientTypes"
					:prepend-icon="$vuetify.icons.values.user"
					color="greenAccent2"
					background-color="white"
				>
					<template v-slot:selection="{ item }">
						{{ $t(item.text) }}
					</template>
					<template v-slot:item="{ item }">
						{{ $t(item.text) }}
					</template>
				</v-select>

				<!-- <v-text-field
					v-if="recipient_type != $defines.SENDING_TO_STUDENT"
					v-model="message.recipient"
					:rules="[rules.required]"
					outlined
					color="greenAccent2"
					background-color="white"
					:label="$t('app.placeholder.recipient_name')"
					:prepend-icon="$vuetify.icons.values.user"
					:readonly="
						isArrayHavingItem(
							[$defines.SENDING_TO_SELF, $defines.SENDING_TO_MENTOR],
							recipient_type,
						)
					"
				>
				</v-text-field> -->

				<v-select
					v-if="recipient_type == $defines.SENDING_TO_STUDENT"
					v-model="message.recipient"
					:label="$t('app.select_student')"
					outlined
					item-text="name"
					item-value="username"
					:items="students"
					:prepend-icon="$vuetify.icons.values.user"
					color="greenAccent2"
					background-color="white"
				>
				</v-select>

				<v-textarea
					v-model="message.title"
					:rules="[rules.required]"
					rows="2"
					color="greenAccent2"
					background-color="white"
					outlined
					:label="$t('app.placeholder.subject')"
					:prepend-icon="$vuetify.icons.values.title"
				>
					<!-- STT -->
					<span slot="append">
						<SpeechToText
							:text="message.title"
							@speechend="message.title = $event.text"
						></SpeechToText>
					</span>
					<!-- TTS -->
					<span slot="append" class="ms-3">
						<TextToSpeech :text="message.title"></TextToSpeech>
					</span>
					<!-- Translator -->
					<span slot="append" class="ms-3">
						<Translator :text="message.title"></Translator>
					</span>
				</v-textarea>

				<v-textarea
					v-model="message.content"
					:rules="[rules.required]"
					outlined
					color="greenAccent2"
					background-color="white"
					:label="$t('app.placeholder.type_message_here')"
					:prepend-icon="$vuetify.icons.values.message"
				>
					<!-- STT -->
					<span slot="append">
						<SpeechToText
							:text="message.content"
							@speechend="message.content = $event.text"
						></SpeechToText>
					</span>
					<!-- TTS -->
					<span slot="append" class="ms-3">
						<TextToSpeech :text="message.content"></TextToSpeech>
					</span>
					<!-- Translator -->
					<span slot="append" class="ms-3">
						<Translator :text="message.content"></Translator>
					</span>
				</v-textarea>
			</v-form>
			<AppButton
				label="app.send"
				icon="send"
				:class="['ms-8', 'mt-2']"
				@click="sendMessage()"
			></AppButton>
		</v-card-text>
	</v-card>
</template>

<script>
import rules from "@/utils/validation";
import { mapState, mapGetters } from "vuex";
import { notificationWriteMixin } from "@/mixins/notificationsMixin";
import { isArrayHavingItem, sortArrayOfObjects } from "@/utils/helpers";
import SpeechToText from "@/components/plugins/SpeechToText";
import TextToSpeech from "@/components/plugins/TextToSpeech.vue";
import Translator from "@/components/plugins/Translator.vue";

export default {
	name: "SendNotification",

	mixins: [notificationWriteMixin],

	data() {
		return {
			rules,
			isArrayHavingItem,
			recipient_type: null,
			message: {
				recipient: null,
				title: null,
				content: null,
			},
			students: [],
		};
	},

	components: {
		TextToSpeech,
		SpeechToText,
		Translator,
	},

	watch: {
		recipient_type(newVal, oldVal) {
			if (newVal === this.$defines.SENDING_TO_SELF) {
				this.message.recipient = this.user.username;
			} else if (newVal === this.$defines.SENDING_TO_MENTOR) {
				this.message.recipient = "mentor";
			} else {
				this.message.recipient = null;
			}
		},
	},

	computed: {
		...mapState({
			user: (state) => state.user.user.user,
		}),

		...mapGetters({
			getRequiredInfo: "notification/getRequiredInfo",
			isMentor: "user/isMentor",
			fullName: "user/fullName",
			userHasMentor: "user/hasMentor",
		}),

		recipientTypes() {
			let types = [
				{
					text: "app.myself",
					value: this.$defines.SENDING_TO_SELF,
				},
			];
			// If user has any mentor, allow "send to mentor"
			if (this.userHasMentor) {
				types.push({
					text: "app.mentor",
					value: this.$defines.SENDING_TO_MENTOR,
				});
			}
			// If user himself is a mentor, allow "send to student"
			if (this.isMentor) {
				types.push({
					text: "app.student",
					value: this.$defines.SENDING_TO_STUDENT,
				});
			}

			return types;
			// {
			// 	text: "app.others",
			// 	value: this.$defines.SENDING_TO_OTHERS,
			// },
		},
	},

	mounted() {
		if (this.isMentor) {
			this.getStudentsList();
		}
	},

	methods: {
		async getStudentsList() {
			try {
				// fetch students list
				let response = await this.$store.dispatch("user/getStudents");
				// pluck name and username from list and set.
				let res = response.map((item) => {
					return {
						username: item.user.username,
						name: this.fullName(item.user),
					};
				});
				// sort the students names
				if (res && res.length) {
					this.students = sortArrayOfObjects(res, "name");
				}
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async sendMessage() {
			try {
				this.$loader.start();

				if (!this.$refs.form.validate()) {
					throw new Error("app.ntfy.err.fill_required_fields", {
						cause: "werCustom",
					});
				}

				let response = await this.$store.dispatch("notification/send", {
					recipient:
						this.recipient_type === this.$defines.SENDING_TO_SELF
							? "memo"
							: this.message.recipient,
					msg_title: this.$xss.sanitize(this.message.title),
					msg_content: this.$xss.sanitize(this.message.content),
				});

				// Now, Write this message to firebase
				await this.writeNotification(response);

				// Push message to outbox state
				this.$store.commit("notification/PUSH_TO_OUTBOX", {
					notification: this.getRequiredInfo(response),
				});

				this.$announce.success("app.ntfy.succ.message_sent");

				this.$refs.form.reset();
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
::v-deep .v-input__prepend-outer .v-icon {
	color: var(--v-amberDarken1-base) !important;
}
</style>
