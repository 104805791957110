<template>
	<v-card v-if="selected_id && notification" flat height="100%" tile outlined>
		<!-- Post new message button -->
		<v-tooltip :left="!$vuetify.rtl" :right="$vuetify.rtl">
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					:style="styleObj"
					fab
					v-bind="attrs"
					v-on="on"
					color="amberDarken1"
					dark
					@click="updateNotificationMode()"
				>
					<v-icon>$vuetify.icons.values.edit</v-icon>
				</v-btn>
			</template>
			<span>{{ $t("app.send_new_msg") }}</span>
		</v-tooltip>

		<!-- Message preview section -->
		<v-card-text class="py-3 subtitle-1 black--text font-weight-bold">
			<v-row no-gutters>
				<v-col sm="1">
					<v-btn icon color="black" @click="updateNotificationMode()">
						<v-icon>{{
							$vuetify.icons.values[$vuetify.rtl ? "right_arrow" : "left_arrow"]
						}}</v-icon>
					</v-btn>
				</v-col>
				<v-col>
					<div
						align="center"
						class="pt-1 subtitle-1 black--text font-weight-bold"
					>
						{{ isInbox ? $t("app.send_by") : $t("app.sent_to") }}
					</div>
				</v-col>
			</v-row>
		</v-card-text>

		<v-divider></v-divider>

		<v-card-text>
			<v-card-title>
				<v-row no-gutters>
					<v-col sm="1">
						<v-avatar :color="isInbox ? 'success' : 'warning'" size="60">
							<span class="white--text text-h5">
								{{
									(isInbox
										? notification.sender.username
										: notification.reciever.username)[0] | capitalize
								}}
							</span>
						</v-avatar>
					</v-col>
					<v-col>
						<span class="black--text font-weight-bold">
							{{
								isInbox
									? notification.sender.username
									: notification.reciever.username | capitalize
							}}
						</span>
						<div class="body-2">
							{{ moment(notification.create_at).format("ll, hh:mm A") }}
						</div>
					</v-col>
				</v-row>
			</v-card-title>

			<v-card-text class="px-2">
				<v-card-title
					class="pb-0 black--text"
					v-html="notification.msg_title"
				></v-card-title>

				<v-card-text
					class="pt-0 subtitle-1 greyDarken4--text"
					v-html="notification.msg_content"
				></v-card-text>
			</v-card-text>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import moment from "moment";
import { mapState } from "vuex";

export default {
	name: "PreviewNotification",

	props: {
		type: {
			required: true,
		},
	},

	data() {
		return {
			moment,
			notification: null,
		};
	},

	computed: {
		...mapState({
			selected_id: (state) => state.notification.selected_id,
		}),

		isInbox() {
			return this.type == this.$defines.NOTIFICATION_TYPE_INBOX;
		},

		isOutbox() {
			return this.type == this.$defines.NOTIFICATION_TYPE_OUTBOX;
		},

		styleObj() {
			return {
				position: "absolute",
				bottom: "30px",
				[this.$vuetify.rtl ? "left" : "right"]: "30px",
			};
		},
	},

	watch: {
		selected_id(newVal, oldVal) {
			if (newVal) {
				this.getMessage();
			}
		},
	},

	mounted() {
		this.getMessage();
	},

	methods: {
		async getMessage() {
			try {
				if (!this.selected_id) return;

				this.$loader.start();

				this.notification = await this.$store.dispatch("notification/get", {
					id: this.selected_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		updateNotificationMode() {
			try {
				this.$loader.start();

				this.$store.commit("notification/UPDATE_NOTIFICATION_MODE", {
					preview_mode: false,
					selected_id: null,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>
