<template>
	<v-card flat height="100%">
		<!-- If messages exist -->
		<v-list
			three-line
			tile
			v-if="items.length"
			class="messages-list-wr overflow-y-auto"
		>
			<template v-for="(item, index) in items">
				<!-- Avtar of users -->
				<v-list-item
					:key="item.title"
					:class="[item.msg_new ? newNotificationClass : getStyleClass(item)]"
					@click="updateNotification(item)"
				>
					<v-list-item-avatar :color="isInbox ? 'success' : 'warning'">
						<v-avatar>
							<span class="white--text text-h6">
								{{
									(isInbox ? item.sender.name : item.reciever.name)[0]
										| capitalize
								}}
							</span>
						</v-avatar>
					</v-list-item-avatar>

					<v-list-item-content>
						<!-- Message owner -->
						<v-list-item-title class="font-weight-bold">
							<v-row no-gutters>
								<v-col>
									{{
										isInbox ? item.sender.name : item.reciever.name | capitalize
									}}
								</v-col>
							</v-row>
						</v-list-item-title>

						<!-- Message title -->
						<v-list-item-subtitle
							class="font-weight-bold"
							v-html="item.msg_title"
						></v-list-item-subtitle>

						<!-- Message subtitle -->
						<v-list-item-subtitle
							v-html="item.msg_content"
						></v-list-item-subtitle>
					</v-list-item-content>

					<v-list-item-action justify="center">
						<v-list-item-action-text
							v-text="moment(item.create_date).fromNow()"
						></v-list-item-action-text>
						<v-menu offset-x>
							<template v-slot:activator="{ on, attrs }">
								<v-icon v-bind="attrs" v-on="on">
									$vuetify.icons.values.menu_horizontal
								</v-icon>
							</template>
							<v-list class="py-0">
								<v-list-item @click="deleteNotification(item)">
									<v-list-item-title>
										<v-icon>$vuetify.icons.values.delete</v-icon>
										{{ $t("app.delete") }}
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-list-item-action>
				</v-list-item>

				<v-divider
					v-if="isInbox ? index + 1 < inbox.length : index + 1 < outbox.length"
					:key="index"
					inset
				></v-divider>
			</template>
		</v-list>

		<!-- If messages does not exist -->
		<v-card-text v-else class="pt-15">
			<NoData heading="app.no_messages_yet" :show_action="false"></NoData>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import { notificationRemoveMixin } from "@/mixins/notificationsMixin.js";
import NoData from "@/components/shared/NoData.vue";

export default {
	name: "Notifications",

	mixins: [notificationRemoveMixin],

	data() {
		return {
			moment,
		};
	},

	props: {
		type: {
			required: true,
		},
	},

	components: {
		NoData,
	},

	computed: {
		...mapState({
			inbox: (state) => state.notification.inbox,
			outbox: (state) => state.notification.outbox,
			selected_id: (state) => state.notification.selected_id,
		}),

		items() {
			if (this.isInbox) return this.inbox;
			if (this.outbox) return this.outbox;
		},

		isInbox() {
			return this.type === this.$defines.NOTIFICATION_TYPE_INBOX;
		},

		isOutbox() {
			return this.type === this.$defines.NOTIFICATION_TYPE_OUTBOX;
		},

		newNotificationClass() {
			return this.isInbox ? "light-green lighten-4" : null;
		},
	},

	methods: {
		getStyleClass(notification) {
			try {
				return this.selected_id === notification.id ? "elevation-6" : null;
			} catch (error) {
				this.$announce.error(error);
			}
		},

		async updateNotificationMode(preview_mode = false, selected_id = null) {
			try {
				this.$loader.start();

				await this.$store.commit("notification/UPDATE_NOTIFICATION_MODE", {
					preview_mode,
					selected_id,
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async updateNotification(notification) {
			try {
				this.$loader.start();

				// If msg is new then mark it as read
				if (this.isInbox && notification.msg_new) {
					// Update the msg status at backend
					await this.$store.dispatch("notification/update", {
						id: notification.id,
						type: this.type,
						msg_new: false,
					});

					// Remove notification from fb too because it has been read
					await this.removeNotification({
						id: notification.id,
						reciever_id: notification.reciever.id,
					});
				}
				// Then, update status in state
				await this.updateNotificationMode(true, notification.id);
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},

		async deleteNotification(notification) {
			try {
				this.$loader.start();

				// If deleted notification is in preview then first remove the preview
				await this.updateNotificationMode();

				// To delete the notification we need to update a property.
				await this.$store.dispatch("notification/update", {
					id: notification.id,
					type: this.type,
					operation: "delete",
					// here are two different properties for inbox and outbox
					[this.isInbox ? "inbox_logical_del" : "outbox_logical_del"]: true,
				});

				this.$announce.success("app.ntfy.succ.notification_deleted");

				/**
				 * Firebase has only inbox notifications so delete only when it is
				 * being deleted from the inbox.
				 */
				if (this.isInbox) {
					await this.removeNotification({
						id: notification.id,
						reciever_id: notification.reciever.id,
					});
				}
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
</script>

<style scoped>
>>> .messages-list-wr {
	max-height: 75vh;
}
</style>
